import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import MoreIcon from "@mui/icons-material/MoreVert";
import silogo from "../../../assets/images/si-logo.svg";
import Link from "@mui/material/Link";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import "../../../assets/css/custom.css";
import LogoutIcon from "components/scroll/LogoutIcon";
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      marginTop="52px"
      {...props}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img className="silogo" src={silogo} alt="ServingIntel" />
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function BottomNav() {
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        sx={{
          boxShadow: 0,
          top: "auto",
          bottom: 0,
          height: "120px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label=""
            sx={{
              transform: "scale(1.4)",
              display: "none",
              "&:hover": { backgroundColor: "#367c2b" },
            }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
          <StyledFab
            style={{ width: "fit-content" }}
            id="nohover"
            disableautofocus
            disableenforcefocus
          >
            {enableNewUI == 0 ? (
              <Copyright position="centered"
                sx={{
                  top: "auto",
                  bottom: 0,
                  "&:hover": { backgroundColor: "none" },
                  "&:focus": { backgroundColor: "none" },
                  "&:active": { backgroundColor: "none" }
                }}
              />) : null}
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />

          {/* <IconButton
            color="inherit"
            href="/"
            aria-label=""
            sx={{ marginTop: "20px", transform: "scale(1.6)", backgroundColor: "#00296F", "&:hover": {backgroundColor: "#367c2b"}}}
          >
            <LogoutTwoToneIcon sx={{ color:"#FFFFFF", fontSize: "13pt" }}/>

          </IconButton> */}
          <LogoutIcon />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
